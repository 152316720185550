import Web3 from 'web3';
import CircumstancerArtifact from './artifacts/circumstancer.js';

const { address, abi } = CircumstancerArtifact;

const useContractCircumstancer = async () => {

    if ( window.ethereum ) {
        try {
            const web3 = new Web3(window.ethereum);
            const chainId = await web3.eth.getChainId();
            return new web3.eth.Contract(
                abi,
                address[chainId]
            )
        } catch (err) {
            console.log(err);
        }
    }

}

export default useContractCircumstancer;