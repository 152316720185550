<template>
  <div>
    <div
      v-if="this.$router.currentRoute.path === '/land'"
      class="bg-darkGray p-15 lg:p-1 fixed w-screen lg:w-full z-50 shadow"
    >
      <LandNavbar></LandNavbar>
    </div>
    <div
      v-else
      class="bg-gray1 p-15 lg:p-1 fixed w-screen lg:w-full z-50 shadow"
    >
      <Navbar></Navbar>
    </div>

    <NetworkNotSupported v-if="!isSupportedRed && !hiddenBtn"
      >></NetworkNotSupported
    >
    <ButtonJoinUsDiscord></ButtonJoinUsDiscord>
    <router-view />
    <ToasterSuccess v-if="toaster.show"></ToasterSuccess>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Navbar from "./components/Navbar.vue";
import LandNavbar from "./components/LandNavbar.vue";
import NetworkNotSupported from "./components/NetworkNotSupported.vue";
import ButtonJoinUsDiscord from "./components/ButtonJoinUsDiscord.vue";
import ToasterSuccess from "./components/ToasterSuccess.vue";

export default {
  name: "App",
  data() {
    return {
      hiddenBtn: false,
    };
  },
  components: {
    Navbar,
    LandNavbar,
    NetworkNotSupported,
    ButtonJoinUsDiscord,
    ToasterSuccess,
  },
  async mounted() {
    this.CHECK_WALLET();
  },
  computed: {
    ...mapState(["isSupportedRed", "toaster"]),
  },
  methods: {
    ...mapActions(["CHECK_WALLET"]),
  },
};
</script>
