<template>
  <div class="relative inline-block text-left w-full">
    <div>
      <button
        class="inline-flex"
        id="menu-button"
        aria-expanded="true"
        aria-haspopup="true"
        @click="changeStatus()"
      >
        <img
          src="../assets/icons/menu-mobile-outline.svg"
          alt="Menu"
          class="inline-block lg:hidden"
        />
      </button>

      <div
        class="flex absolute mt-15 w-screen h-screen rounded-md shadow-lg box -left-30 bg-black bg-opacity-50"
        :class="{
          'transition ease-out duration-100': hidde,
          'transition ease-in duration-75': !hidde,
        }"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabindex="-1"
        v-if="!hidde"
      >
        <div class="py-1 w-8/12 h-screen bg-gray1 transitionx" role="none">
          <router-link to="/">
            <button
              class="block px-4 py-4 text-sm font-light uppercase"
              @click="changeStatus()"
            >
              Home
            </button>
          </router-link>

          <router-link to="/#utilities">
            <button
              class="block px-4 py-4 text-sm font-light uppercase"
              @click="changeStatus()"
            >
              Utilities
            </button>
          </router-link>
          <router-link to="/#roadmap">
            <button
              class="block px-4 py-4 text-sm font-light uppercase"
              @click="changeStatus()"
            >
              Road Map
            </button>
          </router-link>

          <router-link to="/land">
            <button
              class="block px-4 py-4 text-sm font-light uppercase"
              @click="changeStatus()"
            >
              369 Land
            </button>
          </router-link>
          <div class="flex items-center mt-15">
            <a href="" target="_blank" class="mx-15" @click="changeStatus()"
              ><img
                src="../assets/icons rrss/opensea-black.svg"
                alt="OpeanSea link"
                class="hidden"
            /></a>
            <a
              href="https://etherscan.io/address/0x4a9cbDE757660507cE30ab7792732D456b117fdB"
              target="_blank"
              class="mr-15 inline-block"
              @click="changeStatus()"
              ><img
                src="../assets/icons rrss/etherscan-black.svg"
                alt="Etherscan link"
            /></a>
            <a
              href="https://discord.gg/9mRcJSHmh9"
              target="_blank"
              @click="changeStatus()"
              ><img
                src="../assets/icons rrss/discord-black.svg"
                alt="Discord link"
            /></a>
            <a
              href="https://www.instagram.com/circumstancer_/"
              target="_blank"
              class="mx-15 inline-block"
              @click="changeStatus()"
              ><img
                src="../assets/icons rrss/instagram-black.svg"
                alt="Instagram link"
            /></a>
            <a
              href="https://twitter.com/Circumstancer_"
              target="_blank"
              @click="changeStatus()"
              ><img
                src="../assets/icons rrss/twitter-black.svg"
                alt="Twitter link"
                class="inline-block"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenuBurguer",
  data() {
    return {
      hidde: true,
    };
  },
  methods: {
    changeStatus() {
      this.hidde = !this.hidde;
      if (!this.hidde) {
        document.body.classList.add("modal-open");
      } else {
        document.body.classList.remove("modal-open");
      }
    },
  },
};
</script>

<style scoped>
.transitionx {
  animation: reveal 1s forwards;
}
@keyframes reveal {
  from {
    clip-path: inset(0 100% 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}
</style>
