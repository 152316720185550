import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../pages/Home.vue"),
  },

  {
    path: "/land",
    name: "land",
    component: () => import("../pages/Land.vue"),
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash === "") {
      return { x: 0, y: 0 };
    }
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
  },
});

export default router;
