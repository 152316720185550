import Vue from "vue";
import Vuex from "vuex";
import Web3 from "web3";
import useContractCircumstancer from "../contract/index";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    hasMetamask: false,
    isSupportedRed: true,
    isConnected: false,
    currentAccount: "",
    minAccount: "",
    currentBalance: 0,
    remainingSupply: 0,
    circumstancerSC: null,
    toaster: {
      show: false,
      message: "",
    },
  },
  mutations: {
    setIsConnected(state, status) {
      localStorage.setItem("previouslyConnected", "true");
      state.isConnected = status;
    },
    setHasMetamask(state, status) {
      state.hasMetamask = status;
    },
    setSupportedRed(state, status) {
      state.isSupportedRed = status;
      if (!status) {
        state.isConnected = false;
      }
    },
    setCurrentAccount(state, account) {
      state.currentAccount = account;
      state.minAccount = account
        ? `${account.slice(0, 5)}...${account.slice(
            account.length - 4,
            account.length
          )}`
        : null;
    },
    setCurrentBalance(state, balance) {
      state.currentBalance = balance;
    },
    setCircumstancerSC(state, sc) {
      state.circumstancerSC = sc;
    },
    setShowToaster(state, payload) {
      state.toaster = payload;
    },
  },
  actions: {
    CLEAN_DATA: ({ commit }) => {
      commit("setCurrentAccount", "");
      commit("setIsConnected", false);
      commit("setCurrentBalance", 0);
      localStorage.removeItem("previouslyConnected");
    },
    CHECK_WALLET: async ({ dispatch, commit }) => {
      if (window.ethereum) {
        commit("setHasMetamask", window.ethereum.isMetaMask);

        const web3 = new Web3(window.ethereum);
        const chainId = await web3.eth.getChainId();

        switch (chainId) {
          case 1:
          case 4:
            commit("setSupportedRed", true);
            if (localStorage.getItem("previouslyConnected") === "true") {
              const accounts = await web3.eth.getAccounts();
              if (accounts.length > 0) {
                dispatch("INIT_METAMASK");
              }
            }

            break;
          default:
            commit("setSupportedRed", false);
            dispatch("CLEAN_DATA");
            break;
        }

        // Events Listener
        window.ethereum.on("chainChanged", (networkId) => {
          const status = networkId === "0x1" || networkId === "0x4";
          commit("setSupportedRed", status);
          if (
            status &&
            localStorage.getItem("previouslyConnected") === "true"
          ) {
            dispatch("INIT_METAMASK");
          }
        });

        window.ethereum.on("accountsChanged", (_accounts) => {
          if (_accounts.length > 0) {
            commit("setCurrentAccount", _accounts[0]);
            commit("setIsConnected", true);
          } else {
            dispatch("CLEAN_DATA");
          }
        });
      } else {
        commit("setHasMetamask", false);
        dispatch("CLEAN_DATA");
      }
    },
    INIT_METAMASK: async ({ commit, dispatch }) => {
      if (window.ethereum) {
        try {
          const web3 = new Web3(window.ethereum);
          const account = await web3.eth.requestAccounts();
          const balance = await web3.eth.getBalance(account[0]);

          // is Connected
          commit("setIsConnected", true);

          // Set Current Account
          commit("setCurrentAccount", account[0]);

          // Set Balance
          commit("setCurrentBalance", (balance / 1e18).toFixed(4));

          dispatch("GET_CONTRACT_DATA");
        } catch (err) {
          dispatch("CLEAN_DATA");
        }
      } else {
        commit("setHasMetamask", false);
        dispatch("CLEAN_DATA");
      }
    },
    GET_CONTRACT_DATA: async ({ state, commit }) => {
      if (state.isConnected && state.isSupportedRed) {
        const circumstancer = await useContractCircumstancer();
        commit("setCircumstancerSC", circumstancer);
        state.remainingSupply = await circumstancer.methods
          .getRemainingSupply()
          .call();
      }
    },
    showToaster: ({ commit }, payload) => {
      commit("setShowToaster", payload);
      console.log(payload);
      setTimeout(() => {
        commit("setShowToaster", {
          show: false,
          message: "",
        });
        console.log("False");
      }, 3000);
    },
  },
});
