<template>
  <div class="fixed w-full text-center py-4 lg:px-4 mt-72 z-50">
    <div
      class="p-2 bg-yellow-100 items-center text-yellow-700 leading-none lg:rounded-full flex lg:inline-flex"
      role="alert"
    >
      <span
        class="text-white flex rounded-full bg-yellow-500 uppercase px-2 py-1 text-xs font-bold mr-3"
        >Warning!
      </span>
      <span class="font-semibold mr-2 text-left flex-auto"
        >You are on a disallowed network, please switch to the Ethereum
        network.</span
      >
      <svg
        class="fill-current h-6 w-6 text-yellow-500"
        @click="hiddenBtn = true"
        role="button"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <title>Close</title>
        <path
          d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "NetworkNotSupported",
};
</script>
