<template>
  <div id="home" class="flex lg:justify-between mx-15 lg:mx-60 mt-0 lg:my-15">
    <div
      v-if="this.$router.currentRoute.path === '/land'"
      class="bg-darkGray lg:hidden w-screen z-50"
    >
      <LandMenuMobile></LandMenuMobile>
    </div>
    <div v-else class="bg-gray1 p-15 lg:hidden w-screen z-50 shadow">
      <MenuBuguer class="inline-block lg:hidden"></MenuBuguer>
    </div>
    <div class="flex items-center">
      <router-link
        to="/"
        exact-active-class="underline"
        class="hidden lg:inline-block lg:text-2base lg:leading-12 lg:tracking-widest lg:mx-30 lg:hover:underline lg:uppercase"
      >
        <img
          src="../assets/circumstancer-rounded.svg"
          alt="Brand Circumstancer"
          class="w-45"
        />
      </router-link>

      <a href="/#utilities"
        ><span
          class="hidden lg:inline-block lg:text-2base lg:text-white lg:leading-12 lg:tracking-widest lg:mx-30 lg:hover:underline lg:uppercase"
          >Utilities</span
        ></a
      >

      <a href="/#roadmap"
        ><span
          class="hidden lg:inline-block lg:text-2base lg:text-white lg:leading-12 lg:tracking-widest lg:mx-30 lg:hover:underline lg:uppercase"
          >Road Map</span
        ></a
      >

      <router-link
        to="/land"
        exact-active-class="underline"
        class="hidden lg:inline-block lg:text-2base lg:text-white lg:leading-12 lg:tracking-widest lg:mx-30 lg:hover:underline lg:uppercase"
      >
        369 Land
      </router-link>
    </div>
    <div class="flex items-center">
      <a href="" target="_blank" class="mx-15"
        ><img
          src="../assets/icons rrss/opensea-white.svg"
          alt="OpeanSea link"
          class="hidden"
      /></a>
      <a
        href="https://etherscan.io/address/0x4a9cbDE757660507cE30ab7792732D456b117fdB"
        target="_blank"
        class="mx-15 hidden lg:inline-block"
        ><img
          src="../assets/icons rrss/etherscan-white.svg"
          alt="Etherscan link"
      /></a>
      <a href="https://discord.gg/9mRcJSHmh9" target="_blank"
        ><img
          src="../assets/icons rrss/discord-white.svg"
          alt="Discord link"
          class="hidden lg:inline-block"
      /></a>
      <a
        href="https://www.instagram.com/circumstancer_/"
        target="_blank"
        class="mx-15 hidden lg:inline-block"
        ><img
          src="../assets/icons rrss/instagram-white.svg"
          alt="Instagram link"
      /></a>
      <a href="https://twitter.com/Circumstancer_" target="_blank"
        ><img
          src="../assets/icons rrss/twitter-white.svg"
          alt="Twitter link"
          class="hidden lg:inline-block"
      /></a>
    </div>
  </div>
</template>

<script>
import MenuBuguer from "./MenuBurguer.vue";
import LandMenuMobile from "./LandMenuMobile.vue";

export default {
  name: "LandNavbar",
  components: { MenuBuguer, LandMenuMobile },

  computed: {
    isCompany() {
      return this.$router.currentRoute.path === "/land";
    },
  },
};
</script>
