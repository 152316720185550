<template>
  <div>
    <button
      class="hidden lg:inline-block mr-30 mb-15 fixed bottom-0 right-0 z-30"
    >
      <a href="https://discord.com/invite/9mRcJSHmh9" target="_blanck">
        <img
          src="../assets/icons rrss/discord-black.svg"
          alt="link discord"
          class="w-60"
        />
      </a>
    </button>
  </div>
</template>

<script>
export default {
  name: "ButtonJoinUsDiscord",
};
</script>
